import { on_ready, is_elementor_edit } from 'js/utils';
import { hover_intent } from 'js/functions';
import { add_to_cart_ajax_init } from 'src/woocommerce/js/add_to_cart';
import { swiper_mobile_init } from 'js/functions';

import './internals.scss';

on_ready(()=>{

    if( is_elementor_edit() ) return;

    add_to_cart_ajax_init();

    swiper_mobile_init();
    
    // add delay on hover out - to avoid instant close/off
    hover_intent_init();

    document.body.addEventListener('swiper_mobile_init', (e)=>{
        new e.Swiper_Mobile({
            container: '.grid-step', // parent element
            items: '.box', // (.sample_1 .item) wrap with swiper-wrapper > swiper-slide
            arrows: true, // swiper-pagination
            extend_swiper_settings: {
                spaceBetween: 40,
                loop:false,
            }
        })
    })

    document.body.addEventListener('swiper_mobile_init', (e)=>{
        new e.Swiper_Mobile({
            container: '.grid-convert-swiper', // parent element
            items: '.box', // (.sample_1 .item) wrap with swiper-wrapper > swiper-slide
            arrows: true, // swiper-pagination
            extend_swiper_settings: {
                spaceBetween: 40,
                loop:true,
            }
        })
    })
});

function hover_intent_init(){
    document.querySelectorAll('.hover_intent').forEach(el=>{
        const duration = el.dataset.duration || 300;
        hover_intent(el, duration);
    });
}


